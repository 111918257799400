<template>
    <div>
        <template v-if="item.match_rate.type === 'strict'">
            {{ item.match_rate.value }}%
        </template>
        <template v-else-if="item.match_rate.type === 'approx'">
            ~{{ item.match_rate.value }}%
        </template>
        <template v-else>
            --
        </template>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    }
};
</script>